import React from "react"
import styled from "styled-components"
import FlexibleHeaderLS from "./FlexibleHeader_LS"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Polygon from "../../../data/icons/PortfolioLenaStrauss/Polygon_White.svg"

const Container = styled.div`
position:relative;
width:100%;
height:100vh;
min-height:600px;
overflow:hidden;
z-index:3;

& >div{
    position:relative;
    top:20%;

    @media(min-width:601px){
        position:absolute;
        left:8%;
        margin:0;
    }
}

& >svg{
    position:absolute;
    z-index:1;
    visibility:hidden;

    @media(min-width:993px){
        visibility:visible;
    }
}

& svg:nth-child(3){
    width:10%;
    width:190px;
    height:auto;
    bottom:-5%;
    left:237.5px;
    -webkit-transform:rotateZ(-5deg);
        -ms-transform:rotate(-5deg);
            transform:rotateZ(-5deg);
}

& svg:nth-child(4){
    width:15%;
    width:285px;
    height:auto;
    bottom:-2%;
    left:0;
    -webkit-transform:rotateZ(25deg);
        -ms-transform:rotate(25deg);
            transform:rotateZ(25deg);
}

& svg:nth-child(5){
    width:4%;
    width:76px;
    height:auto;
    bottom:22%;
    left:228px;
    -webkit-transform:rotateZ(60deg);
        -ms-transform:rotate(60deg);
            transform:rotateZ(60deg);
}
`

const BackgroundImage = styled(GatsbyImage)`
position:absolute !important;
top:0 !important;
left:0 !important;
width:100%;
height:100%;
`

export default function TopBanner_LS(props){
    return(
        <Container>
            <BackgroundImage loading="eager" image={getImage(props.data.src)} alt="Background Image"/>
            <FlexibleHeaderLS subtitle={props.data.subtitle}>{props.data.title}</FlexibleHeaderLS>
            <Polygon/>
            <Polygon/>
            <Polygon/>
        </Container>
    )
}