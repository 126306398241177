import React from "react"
import styled from "styled-components"


const Container = styled.p`
width:100%;
font-size:16px;
background-color:#ddd;
color:black;
margin:0;
text-align:center;
padding:8px 0;
`


export default function FakePageDisclaimer(props){
    return(
        <Container>
            {props.data}
        </Container>
    )
}