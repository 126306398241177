import React from "react"
import styled from "styled-components"

const Header = styled.div`
width:90%;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
margin: 0 auto;
margin-bottom:30px;

& p:nth-child(1) {
    font-family:IM FELL English;
    font-size:36px;
    color:#444;
    line-height:0px;
    margin:0;
    margin-bottom:25px;

    @media(min-width:601px){
        font-size:64px;
        margin-bottom:50px;
    }
}

& p:nth-child(3) {
    font-family:Didact Gothic;
    font-size:20px;
    color:#444;
    line-height:0;

    @media(min-width:601px){
        font-size:36px;
    }
}

& div {
    width:20%;
    height:3px;
    background-color:#444;
    border-radius:200px;
}

@media(min-width:601px){
    width:400px;
}
`

export default function FlexibleHeader_LS(props){
    return(
        <Header>
            <p>{props.children}</p>
            <div/>
            <p>{props.subtitle}</p>
        </Header>
    )
}