import React from "react"
import styled from "styled-components"
import PhotographerIcon from "../../../data/icons/PortfolioLenaStrauss/Photographer_SVG.svg"

const Header = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-direction:row;
        flex-direction:row;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
gap:20px;

& h2{
    position:relative;
    font-family:IM FELL English;
    font-weight:400;
    font-size:36px;
    color:#444;

    &:after {
        content: '';
        position: absolute;
        left:6%;
        bottom:2px;
        height: 3px;
        width:112.5%;
        background-color:#444444;
        border-radius:200px;
    }

    @media(min-width:601px){
        font-size:64px;
    }
}

& svg {
    width:36px;
    margin-bottom:6px;

    @media(min-width:601px){
        width:64px;
        margin-bottom:12px;
    }
}
`

export default function LenaStraussHeader_LS(props){
    return(
        <Header>
            <h2>{props.children}</h2>
            <PhotographerIcon/>
        </Header>
    )
}