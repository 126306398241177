import React from "react"
import styled from "styled-components"
import FlexibleHeaderLS from "./FlexibleHeader_LS"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Polygon from "../../../data/icons/PortfolioLenaStrauss/Polygon_Grey.svg"

const Container = styled.div`
position:relative;

& >svg{
    position:absolute;
    z-index:-1;
    visibility:hidden;

    @media(min-width:601px){
        visibility:visible;
    }
}

& svg:nth-child(3){
    width:150px;
    height:auto;
    top:50px;
    right:190px;
    -webkit-transform:rotateZ(-25deg);
        -ms-transform:rotate(-25deg);
            transform:rotateZ(-25deg);
}

& svg:nth-child(4){
    width:300px;
    height:auto;
    bottom:-120px;
    left:132px;
    -webkit-transform:rotateZ(22deg);
        -ms-transform:rotate(22deg);
            transform:rotateZ(22deg);
}

& svg:nth-child(5){
    width:60px;
    height:auto;
    bottom:-80px;
    left:520px;
    -webkit-transform:rotateZ(-55deg);
        -ms-transform:rotate(-55deg);
            transform:rotateZ(-55deg);
}

& svg:nth-child(6){
    width:135px;
    height:auto;
    bottom:-230px;
    left:66px;
    -webkit-transform:rotateZ(-5deg);
        -ms-transform:rotate(-5deg);
            transform:rotateZ(-5deg);
}
`

const Images = styled.div`
position:relative;
width:95%;
margin: 0 auto;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:horizontal;
-webkit-box-direction:normal;
-ms-flex-flow:row wrap;
        flex-flow:row wrap;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
gap:15px;

& img {
    width:100%;
}

& div {
    width: 90%;
}

@media(min-width:601px){
    & div{
        width: 40%;
    }
}
`

export default function Gallery_LS(props){
    return(
        <Container>
            <FlexibleHeaderLS subtitle={props.data.subtitle}>{props.data.title}</FlexibleHeaderLS>
            <Images>
            {
                props.data.images.map((item, i) => (
                    <GatsbyImage key={i} image={getImage(item.src)} alt={item.alt}/>
                ))
            }
            </Images>
            <Polygon/>
            <Polygon/>
            <Polygon/>
            <Polygon/>
        </Container>
    )
}