import React from "react"
import styled from "styled-components"

const Container = styled.div`
position:relative;
width:100%;
height:${props => props.height==null?("200"):(props.height)}px;
`

export default function Spacer(props){
    return(
        <Container id={props.id} height={props.height}/>
    )
}