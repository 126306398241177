import React from "react"
import styled from "styled-components"

import BurgerMenu from "../../../data/icons/PortfolioLenaStrauss/BurgerMenu.svg"
import ShakingDiv from "./ShakingDiv"

const NavBig = styled.div`
display:none;

& >div{
    position:fixed;
    top:0;
    right:0;
    z-index:5;
    padding:4px 12px 0px 30px;
    
    background-color:#DDD;
    border-radius:0 0 0 20px;
    -webkit-transition:all 0.2s;
    -o-transition:all 0.2s;
    transition:all 0.2s;
    
    & >div {
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
            -ms-flex-direction:row;
                flex-direction:row;
        -webkit-box-pack:center;
            -ms-flex-pack:center;
                justify-content:center;
        gap:30px;
    }
    
    & p{
        font-size:30px;
        font-family:Didact Gothic;
        color:#444;
        margin:0;
        margin-top:8px;
    }
    
    & svg{
        width:45px;
        height:45px;
        margin-bottom:14px;
    }
}

& div:hover {
    background-color:#FCDCBB;
}

@media(min-width:601px){
    display:block;
}
`

const NavSmall = styled.div`
display:block;

& >div {
    position:fixed;
    top:0;
    right:0;
    z-index:5;
    padding:0px 8px 0px 14px;

    background-color:#DDD;
    border-radius:0 0 0 20px;

    & >div {
        display:-webkit-box;
        display:-ms-flexbox;
        display:flex;
        -webkit-box-orient:horizontal;
        -webkit-box-direction:normal;
            -ms-flex-direction:row;
                flex-direction:row;
        -webkit-box-pack:center;
            -ms-flex-pack:center;
                justify-content:center;
        gap:30px;

        & svg{
            margin:8px 0;
            width:35px;
            height:35px;
        }
    }
}

@media(min-width:601px){
    display:none;
}
`

class NavBar_LS extends React.Component {
    render(){
        return(
            <div>
                <NavBig>
                    <div>
                        <ShakingDiv>
                            <p>MENU</p>
                            <BurgerMenu/>
                        </ShakingDiv>
                    </div>
                </NavBig>
                <NavSmall>
                    <div>
                        <ShakingDiv>
                            <BurgerMenu/>
                        </ShakingDiv>
                    </div>
                </NavSmall>
            </div>
        )
    }
}

export default NavBar_LS;