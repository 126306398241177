import React from "react"
import styled from "styled-components"

const Container = styled.p`
-webkit-animation: ${props => props.shake===1?("shake 0.6s ease 1"):("")};
animation: ${props => props.shake===1?("shake 0.6s ease 1"):("")};

&:hover {
  cursor:pointer;
}

@-webkit-keyframes shake {
  10%, 90% {
    -webkit-transform: translate(-0.5px, 0);
            transform: translate(-0.5px, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate(1.5px, 0);
            transform: translate(1.5px, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate(-3px, 0);
            transform: translate(-3px, 0);
  }

  40%, 60% {
    -webkit-transform: translate(3px, 0);
            transform: translate(3px, 0);
  }
}

@keyframes shake {
  10%, 90% {
    -webkit-transform: translate(-0.5px, 0);
            transform: translate(-0.5px, 0);
  }
  
  20%, 80% {
    -webkit-transform: translate(1.5px, 0);
            transform: translate(1.5px, 0);
  }

  30%, 50%, 70% {
    -webkit-transform: translate(-3px, 0);
            transform: translate(-3px, 0);
  }

  40%, 60% {
    -webkit-transform: translate(3px, 0);
            transform: translate(3px, 0);
  }
}
`

export default function ShakingButton(props){
    const [shake, setShake] = React.useState(0)
    return(
        <Container onClick={() => setShake(1)} onAnimationEnd={() => setShake(0)} shake={shake}>{props.children}</Container>
    )
}