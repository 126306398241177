import React from "react"
import styled from "styled-components"
import ShakingButton from "../DowntownFinances/ShakingButton"
import LenaStraussHeaderLS from "./LenaStraussHeader_LS"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Polygon from "../../../data/icons/PortfolioLenaStrauss/Polygon_Orange.svg"

const Container = styled.div`
position: relative;
width: 80%;
margin: 0 auto;

& >p {
    margin: 0 auto;
    margin-top:80px;
    width:120px;
    text-align:center;
    padding: 8px 5px;
    background-color:#99B2D1;
    color:#444;
    border-radius:50px;
    font-family:IM FELL English;
    font-size:24px;
    -webkit-transition:all 0.2s;
    -o-transition:all 0.2s;
    transition:all 0.2s;

    @media(min-width:601px){
        width:190px;
        padding: 15px 5px;
        font-size:36px;
    }

    &:hover {
        -webkit-filter: drop-shadow(0px 5px 6px #999);
                filter: drop-shadow(0px 5px 6px #999);
    }
}
`

const SubContainer = styled.div`
display: -webkit-box;
display: -ms-flexbox;
display: flex;
width: 100%;

-webkit-box-align:center;

-ms-flex-align:center;

        align-items:center;
-webkit-box-pack: space-evenly;
-ms-flex-pack: space-evenly;
        justify-content: space-evenly;
-webkit-box-orient: horizontal;
-webkit-box-direction: normal;
-ms-flex-flow: row wrap;
        flex-flow: row wrap;
gap:40px;

& p {
    font-family:Didact Gothic;
    font-size:18px;

    margin-top:-10px;

    @media(min-width:601px){
        font-size:24px;
        width:540px;
    }
}
`

const TextContainer = styled.div`
position:relative;
& >svg{
    visibility:hidden;
    z-index:-1;
    position:absolute;

    @media(min-width:1200px){
        visibility:visible;
    }
}

& svg:nth-child(3){
    width:500px;
    height:auto;
    bottom:-20%;
    left:-40%;
    -webkit-transform:rotateZ(-14deg);
        -ms-transform:rotate(-14deg);
            transform:rotateZ(-14deg);
}
`

const ImageContainer = styled.div`
position:relative;
display: -webkit-box;
display: -ms-flexbox;
display: flex;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
& >svg{
    z-index:-1;
    position:absolute;
}

@media(max-width:601px){
    & svg:nth-child(2){
        width:120px;
        width:26%;
        height:auto;
        right:-5%;
        bottom:-30%;
        -webkit-transform:rotateZ(-65deg);
            -ms-transform:rotate(-65deg);
                transform:rotateZ(-65deg);
    }
    
    & svg:nth-child(3){
        width:22%;
        height:auto;
        right:0%;
        bottom:15%;
        -webkit-transform:rotateZ(15deg);
            -ms-transform:rotate(15deg);
                transform:rotateZ(15deg);
    }
    
    & svg:nth-child(4){
        width:18%;
        height:auto;
        left:-5%;
        top:-8%;
        -webkit-transform:rotateZ(-70deg);
            -ms-transform:rotate(-70deg);
                transform:rotateZ(-70deg);
    }
    
    & svg:nth-child(5){
        visibility:hidden;
    }
}

@media(min-width:601px){
    & svg:nth-child(2){
        width:150px;
        height:auto;
        right:-20%;
        bottom:-50%;
        -webkit-transform:rotateZ(-65deg);
            -ms-transform:rotate(-65deg);
                transform:rotateZ(-65deg);
    }
    
    & svg:nth-child(3){
        width:200px;
        height:auto;
        right:5%;
        bottom:-5%;
        -webkit-transform:rotateZ(-35deg);
            -ms-transform:rotate(-35deg);
                transform:rotateZ(-35deg);
    }
    
    & svg:nth-child(4){
        width:80px;
        height:auto;
        left:-25%;
        top:-8%;
        -webkit-transform:rotateZ(-70deg);
            -ms-transform:rotate(-70deg);
                transform:rotateZ(-70deg);
    }
    
    & svg:nth-child(5){
        width:80px;
        height:auto;
        right:-45%;
    }
`

const ProfileImage = styled(GatsbyImage)`
width:90%;

@media(min-width:401px){
    width:75%;
}

@media(min-width:601px){
    width: 350px;
}

& img {
    width: 100%;
    border-radius:200px;
}
`

export default function About_LS(props){
    return(
        <Container>
            <SubContainer>
                <TextContainer>
                    <LenaStraussHeaderLS>{props.data.title}</LenaStraussHeaderLS>
                    <p dangerouslySetInnerHTML={{ __html: props.data.text }}/>
                    <Polygon/>
                </TextContainer>
                <ImageContainer>
                    <ProfileImage image={getImage(props.data.src)} alt={props.data.alt}/>
                    <Polygon/>
                    <Polygon/>
                    <Polygon/>
                    <Polygon/>
                </ImageContainer>
            </SubContainer>
            <ShakingButton>Kontakt</ShakingButton>
        </Container>
    )
}