import React from "react"
import styled from "styled-components"
import ShakingButton from "../DowntownFinances/ShakingButton"
import LenaStraussHeaderWhiteLS from "../LenaStrauß/LenaStraussHeader_White_LS"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Instagram from "../../../data/icons/PortfolioLenaStrauss/instagram.svg"
import Snapchat from "../../../data/icons/PortfolioLenaStrauss/snapchat.svg"
import Twitter from "../../../data/icons/PortfolioLenaStrauss/twitter.svg"
import Polygon from "../../../data/icons/PortfolioLenaStrauss/Polygon_White.svg"

const Container = styled.div`
position:relative;
width:100%;
height:100vh;
min-height:460px;
overflow:hidden;

& >svg{
    position:absolute;
    z-index:1;
    visibility:hidden;

    @media(min-width:993px){
        visibility:visible;
    }
}

& >svg:nth-child(4){
    width:190px;
    height:auto;
    top:-4.5%;
    left:72.5%;
    -webkit-transform:rotateZ(60deg);
        -ms-transform:rotate(60deg);
            transform:rotateZ(60deg);
}

& svg:nth-child(5){
    width:380px;
    height:auto;
    top:4%;
    left:82%;
    -webkit-transform:rotateZ(90deg);
        -ms-transform:rotate(90deg);
            transform:rotateZ(90deg);
}

& svg:nth-child(6){
    width:114px;
    height:auto;
    top:35%;
    left:79%;
    -webkit-transform:rotateZ(10deg);
        -ms-transform:rotate(10deg);
            transform:rotateZ(10deg);
}

& svg:nth-child(7){
    width:190px;
    height:auto;
    bottom:-10%;
    left:152px;
    -webkit-transform:rotateZ(80deg);
        -ms-transform:rotate(80deg);
            transform:rotateZ(80deg);
}

& svg:nth-child(8){
    width:76px;
    height:auto;
    bottom:10%;
    left:256.5px;
    -webkit-transform:rotateZ(50deg);
        -ms-transform:rotate(50deg);
            transform:rotateZ(50deg);
}
`

const Contact = styled.div`
position:relative;
top:12%;
margin:0 auto;

@media(min-width:993px){
    left:1%;
    margin:0;
}

@media(min-width:601px){
    width:450px;
}

& >p {
    margin: 0 auto;
    width:120px;
    text-align:center;
    text-shadow: 0px 0px 5px #000;
    padding: 8px 5px;
    color:#FFF;
    border: solid 2px #FFF;
    box-shadow: 0px 0px 2px 2px rgba(0,0,0,0.2);
    font-family:IM FELL English;
    font-size:24px;
    border-radius:50px;
    -webkit-transition:all 0.2s;
    -o-transition:all 0.2s;
    transition:all 0.2s;

    @media(min-width:601px){
        width:190px;
        padding: 15px 5px;
        font-size:36px;
    }

    &:hover {
        color:#000;
        background-color:#FFF;
        text-shadow:none;
    }
}
`

const Icons = styled.div`
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
margin:0;

& >svg {
    width:65px;

    @media(min-width:601px){
        width:92px;
    }
}
`

const Footnotes = styled.div`
position:absolute;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-orient:vertical;
-webkit-box-direction:normal;
-ms-flex-direction:column;
        flex-direction:column;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
bottom:0;
width:100%;

@media(min-width:993px){
    position:absolute;
    width:300px;
    right:0;
    bottom:0;
    margin:0;
}

& p {
    margin:4px 0;
    color:#FFFFFF;
    text-align:center;
    text-decoration:none;
    text-shadow: 0px 0px 5px #000;
    font-size:18px;
    font-family:Didact Gothic;
    font-weight:400;

    @media(min-width:601px){
        font-size:22px;
        margin:7.5px 0;
    }
}
`

const BackgroundImage = styled(GatsbyImage)`
position:absolute !important;
top:0 !important;
left:0 !important;
width:100%;
height:100%;
`

export default function BottomBanner_LS(props){
    return(
        <Container>
            <BackgroundImage image={getImage(props.data.src)} alt="Background Image"/>
            <Contact>
                <LenaStraussHeaderWhiteLS>{props.data.title}</LenaStraussHeaderWhiteLS>
                <Icons>
                    <Instagram/>
                    <Snapchat/>
                    <Twitter/>
                </Icons>
                <ShakingButton>Kontakt</ShakingButton>
            </Contact>
            <Footnotes>
                <p>© Kudlik & Pfeifer GbR</p>
                <ShakingButton>Impressum</ShakingButton>
                <ShakingButton>Datenschutz</ShakingButton>
            </Footnotes>
            <Polygon/>
            <Polygon/>
            <Polygon/>
            <Polygon/>
            <Polygon/>
        </Container>
    )
}