import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import styled from "styled-components"

import TopBannerLS from "../components/LenaStrauß/TopBanner_LS"
import AboutLS from "../components/LenaStrauß/About_LS";
import GalleryLS from "../components/LenaStrauß/Gallery_LS";
import BottomBannerLS from "../components/LenaStrauß/BottomBanner_LS";
import Spacer from "../components/DowntownFinances/Spacer";
import NavBarLS from "../components/LenaStrauß/NavBar_LS"
import BlueBackButton from "../components/BlueBackButton"
import FakePageDisclaimer from "../components/FakePageDisclaimer"

const PageContainer = styled.div`
overflow-x:hidden;
`

export default function portfolioLenaStrauss({data}){

    const topBanner = data.allPortfolioLenaStraussJson.nodes[0].TopBanner;
    const about = data.allPortfolioLenaStraussJson.nodes[0].About;
    const gallery = data.allPortfolioLenaStraussJson.nodes[0].Gallery;
    const bottomBanner = data.allPortfolioLenaStraussJson.nodes[0].BottomBanner;

    return(
      <PageContainer>
            <Helmet>
                <meta name="robots" content="noindex" charSet="utf-8"/>
                <title>Website Design Beispiel</title>
                <html lang="de"/>
            </Helmet>
          <BlueBackButton/>
          <TopBannerLS data={topBanner}/>
          <NavBarLS/>
          <Spacer/>
          <AboutLS data={about}/>
          <Spacer/>
          <GalleryLS data={gallery}/>
          <Spacer/>
          <BottomBannerLS data={bottomBanner}/>
          <FakePageDisclaimer data={data.allGlobalJson.nodes[0].Disclaimer}/>
      </PageContainer>
      
    )
}

export const query = graphql`
{
    allPortfolioLenaStraussJson {
        nodes {
            TopBanner {
                title
                subtitle
                src {
                    childImageSharp{
                        gatsbyImageData(
                            quality: 70
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                alt
                navButtons{
                    text
                }
            }
            About {
                title
                text
                src {
                    childImageSharp{
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                alt
            }
            Gallery {
                subtitle
                title
                images {
                    alt
                    src {
                        childImageSharp{
                            gatsbyImageData(
                                layout: FULL_WIDTH
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                            )
                        }
                    }
                }
            }
            BottomBanner {
                title
                src {
                    childImageSharp{
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                alt
            }
        }
    }
    allGlobalJson {
        nodes {
            Disclaimer
        }
    }
}
`