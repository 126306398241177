import React from "react"
import styled from "styled-components"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Cross from "../../data/icons/CleanCross.svg"

const Container = styled(AnchorLink)`
position:fixed;
top:16px;
left:16px;
width:55px;
height:55px;
display:-webkit-box;
display:-ms-flexbox;
display:flex;
-webkit-box-align:center;
-ms-flex-align:center;
        align-items:center;
-webkit-box-pack:center;
-ms-flex-pack:center;
        justify-content:center;
-webkit-user-select:none;
-moz-user-select:none;
-ms-user-select:none;
        user-select:none;
-webkit-transition:all 0.2s linear;
-o-transition:all 0.2s linear;
transition:all 0.2s linear;
z-index:20;
background-color:var(--tmain);
border: 3px solid var(--tmain);
border-radius:200px;
-webkit-box-shadow:0px 8px 24px 0px rgba(0,0,0,0.45);
        box-shadow:0px 8px 24px 0px rgba(0,0,0,0.45);

& svg {
    height:80%;
    width:80%;
    cursor:pointer;
    -webkit-user-select:none;
       -moz-user-select:none;
        -ms-user-select:none;
            user-select:none;

    transition: all 0.2s;
}

&:hover {
    background-color:transparent;
    border: 3px solid var(--tmain);
    & svg {
        & path {
            fill:var(--tmain);
        }
    }
}
`

export default function BlueBackButton(props){
    return(
        <Container to={"/websites/"}>
            <Cross/>
        </Container>
    )
}